import "./App.css";
import { Header } from "./Header";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CustomerListPage } from "./CustomerListPage";
import { CustomerDetailsPage } from "./CustomerDetailsPage";
import { TestPage } from "./TestPage";
import React from "react";
import { AppProvider } from "./Context";

const router = createBrowserRouter([
  {
    path: "/",
    element: <CustomerListPage />,
  },
  {
    path: "/customers",
    element: <CustomerListPage />,
  },
  {
    path: "/customers/:customerId",
    element: <CustomerDetailsPage />,
  },
  {
    path: "/test",
    element: <TestPage />
  }
]);

function App() {
  return (
    <div className="app__wrapper">
      <Header />
      <div className="app__contents">
        <AppProvider>
          <RouterProvider router={router} />
        </AppProvider>
      </div>
    </div>
  );
}

export default App;
