export const getUsers = async () => {
  const response = await fetch("/api/users");
  const json = await response.json();
  return json.output.result;
};

export const getCards = async () => {
  const response = await fetch("/api/cards");
  const json = await response.json();
  return json.output.result;
};

export const updateStatus = async (body) => {
  await fetch("/api/block", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });
};


export const getToken = async () => {
 const response = await fetch("/api/token", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const json = await response.json();
  return json.access_token;
};
