import { SuperblocksEmbed } from "@superblocksteam/embed-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const getAppUrlForEnv = (env) => {
    switch (env) {
        case "prod":
        case "prod-eu":
        case "staging":
        default:
            return "https://app.superblocks.com/embed/applications/40524d34-8fdd-4c09-b88b-9a543c6bc1e5"
    }
}

export const TestPage = () => {
    const [token, setToken] = useState("");
    const [customerId, setCustomerId] = useState("customer-123");
    const [properties, setProperties] = useState({ customerId });
    const params = useParams();
    const environment = params.environment || "staging";
    useEffect(() => {
        const getToken = async () => {  
            const tokenResp = await fetch("/api/token");
            const token = await tokenResp.json();
            setToken(token.access_token);
        }
        getToken();
    }, []);
    return <div>
        <h1 style={{ textAlign: "left" }}>Embedding Test Page</h1>
        <div style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
        }}>
            <input id="customerIdInput" type="text" value={customerId} onChange={(e) => setCustomerId(e.target.value)}/>
            <button id="updateProperties" onClick={() => setProperties({ customerId })}>Update</button>
        </div>
        <div id="embed-wrapper" style={{ height: "800px", width: "100%"}}>
           {token ?  <SuperblocksEmbed
                src={getAppUrlForEnv(environment)}
                properties={properties}
                token={token}
            /> : <div>Loading token...</div>}
        </div>
    </div>
}