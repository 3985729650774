import "./Header.css";
import React from "react";
import logo from "./logo.svg";

export const Header = () => {
  return (
    <div className="header flex">
      <div className="flex" style={{ gap: "32px" }}>
        <a href="/" className="header__logo flex">
          <img src={logo} alt="logo" />
          <div>Cash App Admin</div>
        </a>
        <div className="header__tabs flex" style={{ gap: "16px" }}>
          <a href="/customers" className="header__tab header__tab--active">
            Customers
          </a>
          <div className="header__tab">Support</div>
          <div className="header__tab">Banking</div>
          <div className="header__tab">Crypto</div>
        </div>
      </div>
      <div className="header__logout">
        <button className="logout__button">Log out</button>
      </div>
    </div>
  );
};
