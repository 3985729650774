import React, { useCallback, useMemo, useState } from "react";
import { Button, Input, Select, Table } from "antd";
import { Link } from "react-router-dom";
import "./CustomerListPage.css";
import { useApp } from "./Context";

const COUNTRY_CODES = {
  US: "United States",
  CA: "Canada",
  AU: "Australia",
  DE: "Germany",
  FR: "France",
  UK: "United Kingdom",
  JP: "Japan",
};

const renderStatus = (status) => {
  if (status === "inactive") return "--";
  const className = `status status--${status.toLowerCase()}`;
  return <div className={className}>{status}</div>;
};

const TABLE_COLUMNS = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "First name",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    title: "Last name",
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
    render: (countryCode) => COUNTRY_CODES[countryCode] || countryCode,
  },
  {
    title: "Credit card",
    dataIndex: "credit_card_status",
    key: "credit_card_status",
    render: renderStatus,
  },
  {
    title: "Crpyto wallet",
    dataIndex: "crypto_wallet_status",
    key: "crypto_wallet_status",
    render: renderStatus,
  },
  {
    title: "",
    key: "action",
    render: (_, record) => {
      return (
        <Link to={`/customers/${record.id}`}>
          <Button className="action__button">Open</Button>
        </Link>
      );
    },
  },
];

const STATUS_OPTIONS = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Inactive",
    value: "inactive",
  },
  {
    label: "Requested",
    value: "requested",
  },
  {
    label: "Denied",
    value: "denied",
  },
];
export const CustomerListPage = () => {
  const [countryFilter, setCountryFilter] = useState(null);
  const [creditCardFilter, setCreditCardFilter] = useState(null);
  const [cryptoWalletFilter, setCryptoWalletFilter] = useState(null);
  const [searchText, setSearchText] = useState("");
  const countryOptions = useMemo(() => {
    return Object.entries(COUNTRY_CODES).map(([code, name]) => ({
      label: name,
      value: code,
    }));
  }, []);

  const clearAll = useCallback(() => {
    setCountryFilter(null);
    setCreditCardFilter(null);
    setCryptoWalletFilter(null);
  }, []);

  const { users } = useApp();

  const filteredTableData = useMemo(() => {
    return users.filter((customer) => {
      if (countryFilter && customer.country !== countryFilter) {
        return false;
      }
      if (
        creditCardFilter &&
        customer.credit_card_status !== creditCardFilter
      ) {
        return false;
      }
      if (
        cryptoWalletFilter &&
        customer.crypto_wallet_status !== cryptoWalletFilter
      ) {
        return false;
      }
      if (
        searchText &&
        !(
          customer.first_name
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          customer.last_name.toLowerCase().includes(searchText.toLowerCase())
        )
      ) {
        return false;
      }
      return true;
    });
  }, [users, countryFilter, creditCardFilter, cryptoWalletFilter, searchText]);

  return (
    <div className="customer-list">
      <h3 className="customer-list-header">Customers</h3>
      <div className="customer-list-filters">
        <Input
          placeholder="Search"
          style={{ width: 180 }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Select
          onChange={setCountryFilter}
          placeholder="Country"
          style={{ width: 180 }}
          options={countryOptions}
          allowClear={true}
          value={countryFilter}
        />
        <Select
          onChange={setCreditCardFilter}
          placeholder="Credit card"
          style={{ width: 180 }}
          options={STATUS_OPTIONS}
          allowClear={true}
          value={creditCardFilter}
        />

        <Select
          onChange={setCryptoWalletFilter}
          placeholder="Crypto wallet"
          style={{ width: 180 }}
          options={STATUS_OPTIONS}
          allowClear={true}
          value={cryptoWalletFilter}
        />
        <Button onClick={clearAll}>Reset</Button>
      </div>
      <Table
        className="customer-list-table"
        columns={TABLE_COLUMNS}
        dataSource={filteredTableData}
        pagination={false}
        bordered={false}
      />
    </div>
  );
};
