import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { getCards, getUsers } from "./client";

const AppContext = createContext();

export function useApp() {
  return useContext(AppContext);
}

export const AppProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [cards, setCards] = useState([]);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await getUsers();
      setUsers(response);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  }, []);

  const fetchCards = useCallback(async () => {
    try {
      const response = await getCards();
      setCards(response);
    } catch (error) {
      console.error("Failed to fetch cards:", error);
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  const value = {
    users,
    refreshUsers: fetchUsers,
    cards,
    refreshCards: fetchCards,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
