import { SuperblocksEmbed } from "@superblocksteam/embed-react";
import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { getToken } from "./client";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Button } from "antd";
import "./CustomerDetailsPage.css";
import block from "./block.svg";
import { updateStatus } from "./client";
import { useApp } from "./Context";

export const CustomerDetailsPage = () => {
  const { customerId } = useParams();
  const { users, cards, refreshCards, refreshUsers } = useApp();
  useEffect(() => {
    refreshUsers();
  }, [refreshUsers])
  const customerDetails = useMemo(() => {
    return users.find((customer) => `${customer.id}` === customerId) ?? {};
  }, [customerId, users]);
  const appRef = useRef(null);

  const handleBlockUser = useCallback(async () => {
    const isBlocked = customerDetails.status !== "active";
    await updateStatus({ blocked: !isBlocked, userId: customerId });
    refreshUsers();
    appRef.current?.trigger("blockUserEvent");
  }, [refreshUsers, customerDetails, customerId]);

  const totalBalance = useMemo(() => {
    if (customerDetails.status === 'blocked') return 0;
    if (cards && Array.isArray(cards)) {
      return cards.reduce((sum, card) => {
        if (card.status === "blocked") return sum;
        return sum + card.balance;
      }, 0);
    }
    return 0;
  }, [cards, customerDetails?.status]);

  const totalLimit = useMemo(() => {
    if (cards && Array.isArray(cards)) {
      return cards.reduce((sum, card) => {
        if (card.status === "blocked") return sum;

        return sum + card.credit_limit;
      }, 0);
    }
    return 0;
  }, [cards, customerDetails.status]);

  const numActiveCards = useMemo(() => {
    if (!customerDetails) return 0;
    if (customerDetails?.status === 'blocked') return 0;
    if (cards && Array.isArray(cards)) {
      return cards.filter((card) => card.status !== "blocked").length;
    }
    return 0;
  }, [cards, customerDetails.status]);



  const amlStatus = customerDetails.aml_count >= 3 ? "Passed" : "Failed";
  const kycStatus = customerDetails.kyc_count >= 4 ? "Passed" : "Failed";
  const [showBorder, setShowBorder] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key === "s"
      ) {
        event.preventDefault();
        setShowBorder((prevState) => !prevState);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [token, setToken] =  useState("");

  const fetchToken = useCallback(async () => {
    const resp = await getToken();
    setToken(resp);
  }, []);

  useEffect(() => {
    fetchToken();
  }, [fetchToken]);  

  const onEvent = useCallback((eventType) => {
    if (eventType === "creditCardChangedEvent") {
      refreshCards()
    }
  }, [refreshCards])


  if (!customerDetails || !customerDetails.id) return <div>Customer not found</div>;
  return (
    <div className="customer-details-page">
      <div className="customer-details-section">
        <div className="customer-details-user-info">
          {customerDetails.avatar ? (
            <img
              src={customerDetails.avatar}
              alt="avatar"
              className="customer-details-avatar"
            />
          ) : (
            <div className="customer-details-initials">
              {customerDetails.first_name[0]}
              {customerDetails.last_name[0]}
            </div>
          )}
          <div>
            <div className="customer-details-user-name">
              {customerDetails.first_name} {customerDetails.last_name} (ID:{" "}
              {customerDetails.id})
            </div>
            <div className="customer-details-dob">
              {moment(customerDetails.dob, "YYYY-MM-DD").format("MMMM Do YYYY")}
            </div>
          </div>
        </div>
        <Button
          className="block-button"
          icon={customerDetails.status === "active" && <img src={block} />}
          onClick={handleBlockUser}
        >
          {customerDetails.status === "active" ? "Block" : "Unblock"} user
        </Button>
      </div>

      <div
        className="customer-details-section"
        style={{ alignItems: "center" }}
      >
        <div className="customer-details-data-wrapper">
          <div className="customer-details-data">
            <div
              className="customer-details-data-value-large"
              style={{
                fontSize: totalLimit >= 10000 ? "16px" : "18px",
              }}
            >
              <b>${totalBalance.toLocaleString()}</b> / $
              {totalLimit.toLocaleString()}
            </div>
            <div className="customer-details-data-title">Total Balance</div>
          </div>
          <div className="customer-details-data">
            <div className="customer-details-data-value-large">
              <b>{numActiveCards}</b>
            </div>
            <div className="customer-details-data-title">Active Cards</div>
          </div>
          <div className="customer-details-data" style={{ width: "320px" }}>
            <div className="customer-details-key-value">
              <div className="customer-details-data-title">Checking:</div>
              <div className="customer-details-data-value">
                {customerDetails.checking_status}
              </div>
            </div>
            <div className="customer-details-key-value">
              <div className="customer-details-data-title">Savings:</div>
              <div className="customer-details-data-value">
                {customerDetails.savings_status}
              </div>
            </div>
            <div className="customer-details-key-value">
              <div className="customer-details-data-title">Credit Card:</div>
              <div className="customer-details-data-value">
                {customerDetails.credit_card_status}
              </div>
            </div>
            <div className="customer-details-key-value">
              <div className="customer-details-data-title">Crypto Wallet:</div>
              <div className="customer-details-data-value">
                {customerDetails.crypto_wallet_status}
              </div>
            </div>
          </div>
        </div>
        <div className="customer-details-statuses">
          <div className="customer-details-status">
            <div className="customer-details-status-title">AML Status</div>
            <div
              className={`customer-details-status-value customer-details-status-value-${amlStatus.toLowerCase()}`}
            >
              {customerDetails.aml_count}/4 - {amlStatus}
            </div>
          </div>
          <div className="customer-details-status">
            <div className="customer-details-status-title">KYC Status</div>
            <div
              className={`customer-details-status-value customer-details-status-value-${kycStatus.toLowerCase()}`}
            >
              {customerDetails.kyc_count}/5 - {kycStatus}
            </div>
          </div>
        </div>
      </div>
      <div>
      <div
        style={{
          height: "calc(100vh - 300px)",
          width: "100%",
          border: showBorder ? "4px solid #29BBFF" : "1px solid #E8EAED",
          borderRadius: "6px",
        }}
        > 
          {
            !token ? "" :  
            <SuperblocksEmbed 
              src={`https://app.superblocks.com/embed/applications/58126d4f-b88d-4389-aaeb-c4f6178875ee?customerId=${customerId}`}
              properties={{
                customerId
              }}
              id="sb-iframe"
              token={token}
              onEvent={onEvent}
              ref={appRef}
            />
          }
        </div>
      </div>
    </div>
  );
};
